import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import {
  pathBuilder,
  dateBuilder,
  assetPathBuilder,
} from '../utils/pathBuilder';
import { ReactComponent as Calendar } from '../images/svgs/calendar-regular.svg';
import styles from '../styles/components/mainPanelCard.module.scss';

const MainPanelCard = ({ title, previewText, image, date, language }) => {
  const eventDate = new Date(dateBuilder(date));
  const path = pathBuilder(language, date, title);
  const assetPath = assetPathBuilder(image);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return (
    <Link className={styles.content} to={path}>
      <div className={styles.imageContainer}>
        <img src={assetPath} alt={title} className={styles.image} />
      </div>
      <div className={styles.textContainer}>
        <div className={styles.date}>
          <Calendar />
          <span>{eventDate.toLocaleDateString(language, options)}</span>
        </div>
        <div className={styles.header}>
          <h3>{title}</h3>
        </div>
        <p>{previewText}</p>
      </div>
    </Link>
  );
};

MainPanelCard.propTypes = {
  date: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  previewText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default MainPanelCard;
