import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import ContentWrapper from '../components/contentWrapper/contentWrapper';
import Layout from '../components/layout';
import MainPanelCard from '../components/mainPanelCard';
import MessageLJ from '../components/messageLJ/messageLJ';
import TemplateMetadata from './templateMetadata';
import PageNavigation from '../components/pageNavigation';
import Hero from '../components/hero';

import { menuPaths } from '../constants/menuPaths';

import styles from '../styles/templates/paginatedMessagesTemplate.module.scss';
import coverImage from '../images/messages-cover.jpeg';

export default function Template({ data, location, pageContext }) {
  let mainCards = null;

  const title = {
    en: 'Messages from La Jardinera',
    es: 'Mensajes de La Jardinera',
    it: 'Messaggi della Jardinera',
    pt: 'Mensagens da Jardinera',
  };

  if (data.allMarkdownRemark != null) {
    const blogPosts = data.allMarkdownRemark.edges;

    mainCards = blogPosts.map(({ node }) => (
      <MainPanelCard
        key={node.frontmatter.title}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...node.frontmatter}
      />
    ));
  } else {
    mainCards = (
      <div className={styles.emptyQuery}>
        <FormattedMessage id="category.emptyQuery" />
      </div>
    );
  }

  return (
    <Layout location={location}>
      <Hero
        imgSrc={coverImage}
        text={title[pageContext.language]}
        hasIcon={false}
      />
      <ContentWrapper isPageWrapper>
        <TemplateMetadata page="messages" />
        <div className={styles.pageContainer}>
          {mainCards}
          <PageNavigation
            pageContext={pageContext}
            basePath={menuPaths.messages}
          />
          <MessageLJ />
        </div>
      </ContentWrapper>
    </Layout>
  );
}

Template.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    skipVal: PropTypes.number.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query paginatedMessages($language: String!, $skipVal: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {
        frontmatter: { language: { eq: $language }, youTubeLink: { eq: null } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      skip: $skipVal
      limit: $limit
    ) {
      edges {
        node {
          frontmatter {
            date
            title
            image
            language
            previewText
          }
        }
      }
    }
  }
`;
